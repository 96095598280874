import { Card } from "react-bootstrap"
import styled from "styled-components"

const StyledCard = styled(Card)`
  // Extra small devices (portrait phones, less than 576px)
  // No media query for xs since this is the default in Bootstrap

  margin-bottom: 1.5rem !important;
  background: transparent !important;
  border: none;

  .rounded {
    border-radius: 1.5rem !important;
  }

  .card-footer {
    background: transparent;
    border-top: none;
    margin-top: -1.8rem;
  }

  // Newsticker
  p.card-text {
    font-size: 1.6rem !important;
    color: var(--page-bg-color) !important;
    margin-bottom: 1.6rem;
  }

  img.card-img-top {
    border-radius: 15px !important;
    width: 100%;
    height: auto;
  }

  word-wrap: normal;

  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
    border-radius: 2rem;

    // determins how many cards in one row
    min-width: 28rem;

    background: ${props =>
      props.newsTicker ? "transparent" : "var(--component-bg-color)"};
  }

  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
    // determins how many cards in one row
    min-width: 20rem;

    padding: 0.6rem !important;
    border: ${props => (props.newsTicker ? null : "1px solid")};
    margin: ${props => (props.newsTicker ? null : "0.5rem")} !important;
    border-color: var(--page-text-color);

    h2.card-title {
      font-size: 1.7rem;
    }

    p {
      font-size: 1.2rem;
    }
  }

  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
    img.card-img-top {
      border-radius: 20px !important;
    }
    // determins number of cards shown in one row
    // In Newsticker overvlow
    min-width: ${props => (props.newsTicker ? "2rem" : "18rem")};
    background: ${props => (props.newsTicker ? "transparent" : null)};

    p.card-text {
      font-size: 1.2rem !important;
    }

    p {
      font-size: 1.2rem !important;
    }

    .card-body {
      /* padding-bottom: 0; */
    }

    h2.card-title {
      font-size: 1.8rem;
    }
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

export default StyledCard
