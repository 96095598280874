import React from 'react'
import { Card, ListGroupItem } from "react-bootstrap"
import StyledCard from './common/StyledCard'
import RichText from "./richText"
import { day, time, date } from './dateFn'
import ListGroup from 'react-bootstrap/ListGroup'
import StyledLinkButton from './common/StyledLinkButton'
import { Link } from 'gatsby'
import { myContext } from '../context/LanguageContext'
import styled from 'styled-components'
import { PrismicRichText } from '@prismicio/react'

function EventOverviewCard({ event }) {

    if (event) {

        const eventData = event.node.body[0]

        if (eventData.primary.end_date) {
            eventData.primary.endDay = (" - " + day(eventData.primary.end_date));
            eventData.primary.endDate = (" - " + date(eventData.primary.end_date));
        }

        return (
            <StyledCard>
                    <Link to={`/${eventData.primary.slug}`} >
                        {/* no englisch translation at the moment */}
                        {/* <Link to={`../${eventData.primary.slug}`} > */}
                        <Card.Img src={eventData.primary.image.url} alt={eventData.primary.image.alt} className="featured-image-wrapper rounded" />
                    </Link>
                <Card.Body>
                    <Card.Title className="mt-3">
                            <PrismicRichText
                                field={eventData.primary.pageTitle}
                                components={{
                                    heading1: ({ children }) => <h2 className='card-title'>{children}</h2>
                                }}
                            />
                    </Card.Title>
                    <RichText render={eventData.primary.event_teaser} />
                    <ListGroup className="list-group-flush">
                        <StyledListGroupItem >{day(eventData.primary.date_time)}{eventData.primary.endDay}</StyledListGroupItem>
                        <StyledListGroupItem >{date(eventData.primary.date_time)}{eventData.primary.endDate}</StyledListGroupItem>
                        <StyledListGroupItem >{time(eventData.primary.date_time)} - {time(eventData.primary.end_time)}</StyledListGroupItem>
                    </ListGroup>
                </Card.Body>
                <Card.Footer className="mt-1 mb-2">
                    {/* at the moment there are no entlish translation of the eventData details */}
                    <myContext.Consumer>
                        {context => (
                            <StyledLinkButton to={`/${eventData.primary.slug}`}>
                                {/* no english translation at the moment */}
                                {/* <StyledLinkButton to={`../${eventData.primary.slug}`}> */}
                                {!context.isEn ? `${"Weiter lesen..."}` : `${"Learn more..."}`}
                            </StyledLinkButton>
                        )}
                    </myContext.Consumer>
                </Card.Footer>
            </StyledCard>
        )
    } else { return null }

}

export default EventOverviewCard

const StyledListGroupItem = styled(ListGroupItem)`
        color: var(--page-text-color);
        border-color: var(--page-text-color);
        background: transparent;
        font-size: 1rem !important;
    `
